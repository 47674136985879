/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { useEffect } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled
import { css, jsx } from '@emotion/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useIssueCreateSidePanelContext } from './IssueCreateSidePanelContextProvider';

type IssueCreateAiLoadingSidebarProps = {
	children: React.ReactNode;
};

const defaultStyles = css({
	position: 'absolute',
	width: '100%',
	backgroundColor: 'elevation.surface',
	height: '100%',
	top: '0',
	right: '0',
	left: '0',
	bottom: '0',
	display: 'flex',
	flexDirection: 'column',
});

const gradientBar = `
	--angle: 0deg;
	border-left: 2px solid;
	border-image: conic-gradient(from var(--angle), #0065FF, #0469FF, #BF63F3, #FFA900, #0065FF) 1;
	
	animation: 1s rotate linear infinite;
`;

const addAngle = `
@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}
`;
const animateBorder = `
@keyframes rotate {
	to {
		--angle: 360deg;
	}
}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-exported-styles
const rainbowBorder = css(gradientBar, addAngle, animateBorder);

export const IssueCreateAiLoadingSidebar = ({ children }: IssueCreateAiLoadingSidebarProps) => {
	const [{ aiState }] = useIssueCreateSidePanelContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isAiLoading = aiState === 'loading';

	useEffect(() => {
		if (isAiLoading) {
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: 'AI loading state',
					source: 'confluenceHighlightIssueCreate',
				},
			}).fire();
		}
	}, [createAnalyticsEvent, isAiLoading]);

	return (
		<div data-testId="ai-loading-sidebar" css={[defaultStyles, isAiLoading && rainbowBorder]}>
			{children}
		</div>
	);
};
