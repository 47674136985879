import React, { type ReactNode, useCallback, useEffect } from 'react';
import { type MessageDescriptor, useIntl } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';
import Anchor from '@atlaskit/primitives/anchor';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ConfluenceAiIssueCreate } from '@atlassian/ai-issue-create';
import { GicAnywhere, type IssueCreateEmbedSuccessPayload } from '@atlassian/gic-anywhere';
import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';

import { cfetch } from '@confluence/network';
import { useSessionData } from '@confluence/session-data';
import { type FlagType } from '@confluence/flags';
import { fg } from '@confluence/feature-gating';
import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';

import type { IssueCreateSidePanelBodyProps } from './__types__/IssueCreateSidePanelBody';
import type { InsertStorageFragmentRequestPayload } from './__types__/InsertStorageFragmentRequestPayload';
import {
	useIssueCreateSidePanelContext,
	type IssueCreateSidePanelContextType,
} from './IssueCreateSidePanelContextProvider';
import { IssueCreateAiLoading } from './IssueCreateAiLoading';
import { AiCreationError } from './AiCreationError';
import { messages } from './messages';

const noop = () => {};

const aiContentWrapperBaseStyles = xcss({
	display: 'inherit',
	flexGrow: 1,
});
const aiContentWrapperHiddenStyles = xcss({ display: 'none' });

const REFRESH_TIMEOUT = 3000;

export const IssueCreateSidePanelBody = ({ onClose, flags }: IssueCreateSidePanelBodyProps) => {
	const { cloudId } = useSessionData();
	const [state, actions] = useIssueCreateSidePanelContext();
	const {
		contentId,
		contentType,
		summary,
		descriptionAdf,
		aiState,
		isCreatedWithAi,
		highlightedText,
		insertStorageFragmentRequestPayload,
		totalRetries,
		firstAttempt,
	} = state;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { trackAIResultError, trackAIResultView, trackAIResultAction } =
		useAIEventsInstrumentation();
	const aiError = aiState === 'error';

	const persistedOverrideCloudId = fg('jira_ai_issue_create_site_user_preference')
		? localStorage?.getItem(PERSISTED_KEYS_ON_SERVER.PERSISTED_JIRA_CLOUD_ID_PREFERENCE)
		: undefined;

	// no need to override if it's the same site
	const overrideCloudIdForSitePicker =
		persistedOverrideCloudId !== cloudId ? persistedOverrideCloudId : undefined;

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'linkCreateScreen',
				attributes: {
					isManuallyCreated: !isCreatedWithAi,
					isCreatedWithAi,
				},
				source: 'confluenceHighlightIssueCreate',
			},
		}).fire();
	}, [isCreatedWithAi, createAnalyticsEvent]);

	useEffect(() => {
		if (totalRetries > 0 && fg('action_crocs_analytics_change_request')) {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: 'issueCreateSidePanelErrorScreen',
					action: 'retry',
					actionSubject: 'issueCreate',
					attributes: {
						createManually: !isCreatedWithAi,
						tryAgainWithAi: isCreatedWithAi,
						totalRetries,
						firstAttempt,
					},
				},
			}).fire();
		}
	}, [isCreatedWithAi, createAnalyticsEvent, totalRetries, firstAttempt]);

	const isAiLoading = aiState === 'loading';
	const { formatMessage } = useIntl();

	const triggerFlag = useCallback(
		(payload: IssueCreateEmbedSuccessPayload, flagType: FlagType, message: MessageDescriptor) => {
			void flags.showFlag({
				type: flagType,
				title: formatMessage(message, {
					issueKey: payload?.issueKey,
					issueSummary: payload?.summary,
					link: (chunks: ReactNode[]) => (
						<Anchor href={payload?.link} target="_blank">
							{chunks}
						</Anchor>
					),
				}),
				close: 'auto',
			});
		},
		[flags, formatMessage],
	);

	const handleTryAgain = useCallback(() => {
		if (fg('action_crocs_analytics_change_request')) {
			void actions.setTotalRetries();
			void actions.setFirstAttempt(false);
			void actions.setAiState('idle');
		} else {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: 'issueCreateSidePanelErrorScreen',
					action: 'retry',
					actionSubject: 'issueCreate',
					attributes: {
						createManually: false,
						tryAgainWithAi: true,
					},
				},
			}).fire();
			void actions.setAiState('idle');
		}
	}, [actions, createAnalyticsEvent]);

	const handleCreateManually = useCallback(() => {
		if (fg('action_crocs_analytics_change_request')) {
			void actions.setTotalRetries();
			void actions.setFirstAttempt(false);
			void actions.setIsCreatedWithAi(false);
			void actions.setAiState('idle');
		} else {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: 'issueCreateSidePanelErrorScreen',
					action: 'retry',
					actionSubject: 'issueCreate',
					attributes: {
						createManually: true,
						tryAgainWithAi: false,
					},
				},
			}).fire();
			void actions.setIsCreatedWithAi(false);
			void actions.setAiState('idle');
		}
	}, [actions, createAnalyticsEvent]);

	const refreshPageWithTimeout = () => {
		setTimeout(() => {
			window.location.reload();
		}, REFRESH_TIMEOUT);
	};

	const onAiIssueCreateInit = useCallback(() => {
		trackAIResultView({ actionSubjectId: 'aiIssueCreatOnInitComplete' });
	}, [trackAIResultView]);

	const onFailure = useCallback(
		(error: Error | unknown) => {
			if (fg('action_crocs_analytics_change_request')) {
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						source: 'confluenceHighlightIssueCreate',
						action: 'error',
						actionSubject: 'globalIssueCreate',
						attributes: {
							isManuallyCreated: !isCreatedWithAi,
							isCreatedWithAi,
							error,
						},
					},
				}).fire();
				if (isCreatedWithAi) {
					trackAIResultError(
						{
							aiErrorMessage: (error as Error).message,
						},
						{
							attributes: {
								totalRetries,
								firstAttempt,
							},
						},
					);
				}
			} else {
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						source: 'confluenceHighlightIssueCreate',
						action: 'error',
						actionSubject: 'globalIssueCreate',
						attributes: {
							isManuallyCreated: !isCreatedWithAi,
							isCreatedWithAi,
						},
					},
				}).fire();
			}
		},
		[createAnalyticsEvent, isCreatedWithAi, trackAIResultError, totalRetries, firstAttempt],
	);

	const onSuccess = useCallback(
		(payload: IssueCreateEmbedSuccessPayload) => {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: 'confluenceHighlightIssueCreate',
					action: 'created',
					actionSubject: 'issue',
					actionSubjectId: 'confluenceIssueCreate',
					attributes: {
						isManuallyCreated: !isCreatedWithAi,
						isCreatedWithAi,
					},
				},
			}).fire();
			if (isCreatedWithAi) {
				if (fg('action_crocs_analytics_change_request')) {
					trackAIResultAction('issue created', {
						attributes: {
							totalRetries,
							firstAttempt,
						},
					});
				} else {
					trackAIResultAction('issue created');
				}
			}
			triggerFlag(payload, 'success-circle', messages.sidePanelIssueCreateSuccessFlagTitle);

			// Save the cloud id in localStorage if an issue is created
			if (payload?.selectedCloudId && fg('jira_ai_issue_create_site_user_preference')) {
				localStorage.setItem(
					PERSISTED_KEYS_ON_SERVER.PERSISTED_JIRA_CLOUD_ID_PREFERENCE,
					payload.selectedCloudId,
				);
			}

			if (insertStorageFragmentRequestPayload) {
				insertStorageFragmentRequestPayload.xmlModification = constuctSmartlinkTag(payload.link);
				insertContentAtSelectionEnd(insertStorageFragmentRequestPayload)
					.then((data) => {
						// There is a case where the API response is OK, but the response data is false.
						// When this happens, the backend has trouble inserting the ticket to the page and we should show an error toast.
						if (data === false) {
							throw new Error('Failed to insert storage fragment');
						}

						return createAnalyticsEvent({
							type: 'sendTrackEvent',
							data: {
								source: 'confluenceHighlightIssueCreate',
								action: 'created',
								actionSubject: 'link',
								actionSubjectId: 'confluenceIssueCreate',
								attributes: {
									isManuallyCreated: !isCreatedWithAi,
									isCreatedWithAi,
								},
							},
						}).fire();
					})
					.then(() => {
						refreshPageWithTimeout();
					})
					.catch((_error) =>
						triggerFlag(payload, 'error', messages.sidePanelIssueCreateInsertionFailed),
					);
			}
		},
		[
			triggerFlag,
			insertStorageFragmentRequestPayload,
			createAnalyticsEvent,
			isCreatedWithAi,
			trackAIResultAction,
			totalRetries,
			firstAttempt,
		],
	);

	const handleStateChange = useCallback(
		(aiFetchState: IssueCreateSidePanelContextType['aiState']) => {
			if (aiFetchState === 'error' && !fg('action_crocs_analytics_change_request')) {
				trackAIResultError({
					aiErrorMessage:
						'AI suggested fields failed to be fetched within ConfluenceAIIssueCreate component',
					aiErrorCode: 0,
				});
			}
			void actions.setAiState(aiFetchState);
		},
		[actions, trackAIResultError],
	);

	if (aiError) {
		return <AiCreationError onTryAgain={handleTryAgain} onCreateManually={handleCreateManually} />;
	}

	return (
		<>
			{isCreatedWithAi ? (
				<>
					{isAiLoading && <IssueCreateAiLoading />}
					<Box xcss={[aiContentWrapperBaseStyles, isAiLoading && aiContentWrapperHiddenStyles]}>
						<ConfluenceAiIssueCreate
							cloudId={cloudId}
							contentType={contentType}
							pageId={contentId}
							highlightedTextAdf={highlightedText}
							consumer="confluence-highlight-issue-create-ai"
							onInit={onAiIssueCreateInit}
							onSuccess={onSuccess}
							onFailure={onFailure}
							onClose={onClose}
							onStateChange={handleStateChange}
							overrideCloudIdForSitePicker={overrideCloudIdForSitePicker}
						/>
					</Box>
				</>
			) : (
				<GicAnywhere
					cloudId={cloudId}
					consumer="confluence-highlight-issue-create"
					onInit={noop}
					onSuccess={onSuccess}
					onClose={onClose}
					onFailure={onFailure}
					summary={summary}
					descriptionAdf={descriptionAdf}
					overrideCloudId={overrideCloudIdForSitePicker}
				/>
			)}
		</>
	);
};

export const constuctSmartlinkTag = (url: string) => {
	return `<a href="${url}" data-card-appearance="inline">${url}</a>`;
};

const insertContentAtSelectionEnd = async (
	insertStorageFragmentRequestPayload: InsertStorageFragmentRequestPayload,
) => {
	try {
		const restUrl = '/wiki/rest/highlighting/1.0/insert-storage-fragment';

		const response = await cfetch(restUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(insertStorageFragmentRequestPayload),
		});

		if (!response.ok) {
			throw new Error('Request failed');
		}

		return await response.json();
	} catch (error) {
		throw error;
	}
};
